<template>
  <div>
    <el-table
      v-loading="loading"
      :data="messageList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column prop="email" :label="labels.LBL_FROM"> </el-table-column>

      <el-table-column :label="labels.LBL_DATE">
        <template slot-scope="scope">
          {{ scope.row.created_at | readableDate }}
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="labels.LBL_TYPE_2_SEARCH"
          />
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-view"
            circle
            @click="handleView(scope.row)"
          >
          </el-button>

          <el-button
            size="mini"
            type="primary"
            icon="el-icon-chat-dot-square"
            circle
            @click="handleReply(scope.row)"
          >
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    />
  </div>
</template>

<script>
  import _ from "lodash";
  import { labels } from "@/common";
  import { mapState } from "vuex";

  export default {
    name: "Inbox",

    data() {
      return {
        labels,

        unReplied: null,

        search: "",
        params: {
          page: 1,
          reply: 0,
        },
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),

      messageList() {
        return this.unReplied && this.unReplied.data;
      },

      pagination() {
        return this.unReplied && this.unReplied.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadAllMessages();
        },
      },
    },

    methods: {
      async loadAllMessages() {
        this.unReplied = await this.$store.dispatch(
          "feedback/getAllFeedbacks",
          this.params
        );
      },

      async handleSearch(search) {
        this.unReplied = await this.$store.dispatch(
          "feedback/getAllFeedbacks",
          {
            ...this.params,
            search,
          }
        );
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleView({ id }) {
        this.$router.push({
          name: "View Feedback",
          params: { feedbackId: id },
        });
      },

      handleReply({ id }) {
        this.$router.push({ name: "Reply Feedback", params: { replyTo: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store
              .dispatch("feedback/deleteFeedback", { id, reply: 0 })
              .then(() => {
                this.loadAllMessages();
              });
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
