<template>
  <content-wrapper title="Feedbacks">
    <el-tabs tab-position="left" class="tab">
      <el-tab-pane
        :label="labels.LBL_UNREPLIED"
        style="height: 60vh; overflow-y: auto"
      >
        <the-inbox />
      </el-tab-pane>

      <el-tab-pane
        :label="labels.LBL_REPLIED"
        style="height: 60vh; overflow-y: auto"
      >
        <the-sent />
      </el-tab-pane>
    </el-tabs>
  </content-wrapper>
</template>

<script>
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import TheInbox from "@/components/messages/TheInbox";
  import TheSent from "@/components/messages/TheSent";

  export default {
    name: "Feedbacks",

    components: {
      ContentWrapper,
      TheInbox,
      TheSent,
    },

    data() {
      return {
        labels,
      };
    },
  };
</script>

<style scoped>
  .tab {
    height: 60vh;
    background: white;
    padding: 1rem;
  }
</style>
